<template>
  <div>
    <!-- Filters -->
    <b-card>
      <b-row align-v="center" align-h="between">
        <!-- Inicio Contrato -->
        <b-col cols="12" lg="5" md="5">
          <label>Inicio Contrato</label>
          <div class="d-flex align-items-center">
            <feather-icon icon="CalendarIcon" size="20" />
            <flat-pickr
              v-model="filterInicioContrato"
              class="form-control"
              placeholder="dd-mm-yyyy"
              :config="{
                maxDate: filterFinContrato,
                format: 'd-m-Y',
                altFormat: 'd-m-Y',
                allowInput: true,
                altInput: true,
                locale: Spanish,
              }"
            />
          </div>
        </b-col>

        <!-- Fin contrato -->
        <b-col cols="12" lg="5" md="5">
          <label>Fin Contrato</label>
          <div class="d-flex align-items-center">
            <feather-icon icon="CalendarIcon" size="20" />
            <flat-pickr
              v-model="filterFinContrato"
              class="form-control"
              placeholder="dd-mm-yyyy"
              :config="{
                minDate: filterInicioContrato,
                format: 'd-m-Y',
                altFormat: 'd-m-Y',
                allowInput: true,
                altInput: true,
                locale: Spanish,
              }"
            />
          </div>
        </b-col>

        <!-- Filtrar -->
        <b-col cols="12" lg="2" md="2" class="pt-2">
          <b-button
            v-if="!isFiltering"
            variant="outline-primary"
            class="mr-2"
            block
            @click="filterData"
          >
            Filtrar
            <feather-icon icon="FilterIcon" />
          </b-button>

          <b-button
            v-else
            variant="outline-danger"
            class="mr-2"
            block
            @click="clearFilter"
          >
            Limpiar
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar por nombre o apellido..."
              />
              <b-button
                v-if="isAdmin"
                variant="primary"
                @click="editEmpleado('new')"
              >
                <span class="text-nowrap">Crear Empleado</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refEmpleadoListTable"
        class="position-relative"
        :items="fetchEmpleados"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchEmpleados"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <!-- Column: Id -->
        <template #cell(matricula)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="isAdmin ? editEmpleado(data.item.matricula) : null"
          >
            {{ data.item.matricula }}
          </b-link>
        </template>

        <!-- Column: Nombre -->
        <template #cell(apellidos)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.nombre)"
                variant="primary"
                @click="isAdmin ? editEmpleado(data.item.id) : null"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="isAdmin ? editEmpleado(data.item.id) : null"
            >
              {{ `${data.item.apellidos}, ${data.item.nombre}` || "-" }}
            </b-link>
            <small
              >{{ `${data.item.direccion}, ${data.item.poblacion}.` }}<br />
              {{  data.item.dni ? data.item.dni : "-" }}</small
            >
          </b-media>
        </template>

        <!-- Column: Contact -->
        <template #cell(contact)="data">
          <div><strong>Telefono:</strong> {{ data.item.telefono }}</div>
          <div><strong>Email:</strong> {{ data.item.email }}</div>
        </template>

        <!-- Column: Estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.estado) }}
          </b-badge>
        </template>

        <!-- Column: Contrato -->
        <template #cell(contrato)="data">
          <div>
            <strong>Inicio:</strong>
            {{
              data.item.inicio_contrato
                ? parseDate(data.item.inicio_contrato)
                : "-"
            }}
          </div>
          <div>
            <strong>Fin:</strong>
            {{
              data.item.fin_contrato && data.item.fin_contrato !== "2050-01-01"
                ? parseDate(data.item.fin_contrato)
                : "Indefinido"
            }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="between" v-if="isAdmin">
            <b-button
              :id="`edit-${data.item.id}`"
              @click="editEmpleado(data.item.id)"
              variant="success"
              class="btn-icon"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              :target="`edit-${data.item.id}`"
              triggers="hover"
              placement="bottom"
              boundary="window"
            >
              <b>Editar</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
              {{ dataMeta.of }} entradas</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEmpleados"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es";
import useEmpleadosList from "./useEmpleadosList";
import empleadoStoreModule from "./empleadoStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    flatPickr,
  },
  data() {
    return {
      Spanish,
    };
  },
  methods: {
    editEmpleado(id) {
      this.$router.push({
        name: "empleado-edit",
        params: {
          id,
        },
      });
    },
  },
  computed: {
    isAdmin() {
      return this.user.fk_role === 1;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  setup() {
    const EMPLEADO_APP_STORE_MODULE_NAME = "app-empleado";

    // Register module
    if (!store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME))
      store.registerModule(EMPLEADO_APP_STORE_MODULE_NAME, empleadoStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLEADO_APP_STORE_MODULE_NAME))
        store.unregisterModule(EMPLEADO_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "inactivo", value: "0" },
      { label: "activo", value: "1" },
      { label: "pendiente", value: "2" },
    ];

    const {
      fetchEmpleados,
      tableColumns,
      perPage,
      currentPage,
      totalEmpleados,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmpleadoListTable,
      refetchData,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      parseDate,

      // Extra Filters
      filterInicioContrato,
      filterFinContrato,
      filterData,
      isFiltering,
      clearFilter,
    } = useEmpleadosList();

    return {
      fetchEmpleados,
      tableColumns,
      perPage,
      currentPage,
      totalEmpleados,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmpleadoListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      parseDate,

      statusOptions,

      // Extra Filters
      filterInicioContrato,
      filterFinContrato,
      filterData,
      isFiltering,
      clearFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
